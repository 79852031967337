/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  HStack,
  Link,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
} from '@mybridge/ui';
import { forwardRef, useContext } from 'react';
import { SearchContext, SearchTabs } from '../context';
import Styles from './index.module.css';
import { css } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { SearchPeopleFilters } from '../people/filters';
import { SearchPostsFilters } from '../posts/filters';
import { SearchCompanyPagesFilters } from '../pages/filters';
import { SearchTeamsFilters } from '../teams/filters';
import { SearchEventsFilters } from '../events/filters';
import { SearchArticlesFilters } from '../articles/filters';
import { SearchJobsFilters } from '../jobs/filters';

export const SearchSidebar = forwardRef(({ active = 0, ...props }, ref) => {
  // const { isMobile } = useSelector((state) => state.globalData);
  const { activeTab, setActiveTab, query, allSearch } =
    useContext(SearchContext);
  const menu = SearchTabs?.()?.filter?.((menuItem, ind) =>
    query?.query?.length ? true : ind > 0
  );

  // const filteredTabs = menu.filter((item) => {
  //   const keyName = item.name;
  //   return  query?.query?.length ? keyName === 'all' || (
  //     // eslint-disable-next-line no-prototype-builtins
  //     allSearch?.results?.hasOwnProperty(keyName) &&
  //     allSearch?.results[keyName]?.length > 0) : keyName === 'posts' || keyName === 'jobs';

  // });

  // console.log(activeTab);

  const filterTabCase = (menu) => {
    switch (menu) {
      case 'people':
        return { component: <SearchPeopleFilters /> };
      case 'posts':
        return { component: <SearchPostsFilters /> };
      case 'pages':
        return { component: <SearchCompanyPagesFilters /> };
      case 'team':
        return { component: <SearchTeamsFilters /> };
      case 'events':
        return { component: <SearchEventsFilters /> };
      case 'articles':
        return { component: <SearchArticlesFilters /> };
      case 'jobs':
        return { component: <SearchJobsFilters /> };
      default:
        return { component: <></> };
    }
  };
console.log(activeTab,"activeTabactiveTab");
  return (
    <>
      {!isMobile && (
        <Stack display={['none', 'none', 'flex']}>
          {menu?.map?.((mi, miIndex) => (
            <Box key={miIndex} align="stretch">
              <HStack
                className={Styles.tab}
                _hover={{
                  bg:"#b5b4b46e",
                  boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                }}
                borderColor="transparent"
                color={mi.name === activeTab ? 'black' : '#5B5B5B'}
                p={1}
                gap="2"
                fontWeight={mi.name === activeTab ?"700":"600"}
                alignItems="center"
                cursor="pointer"
                onClick={(e) => setActiveTab(mi?.name)}
                justifyContent="flex-start"
                {...(mi.link ? { as: Link, href: mi.link } : {})}
                pr={8}
                borderRadius={'50px'}
                width={'fit-content'}
              >
                <HStack
                  bg={mi.name === activeTab ? '#D9D9D9' : ''}
                  alignItems="center"
                  justifyContent="center"
                  // w="10"
                  // h="10"
                  borderRadius="full"
                  p={3}
                >
                  {mi?.icon?.(mi.name === activeTab ? { color: '' } : {})}
                </HStack>
                <Text pr="4" color={mi.name === activeTab ? 'black' : '#575757'} wordBreak='normal'>{mi.title}</Text>
              </HStack>
              {mi.name === activeTab ? (
                filterTabCase(activeTab)?.component
              ) : (
                <></>
              )}
            </Box>
          ))}
        </Stack>
      )}
      {isMobile && <Stack display={['flex', 'flex', 'none']} width="100%">
        <Tabs index={menu?.findIndex?.((m) => m.name === activeTab)}>
          <TabList overflowX="auto" css={css({
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {display: 'none'},
          '::-webkit-scrollbar-thumb':{display: 'none'},
          '-webkit-overflow-scrolling': 'touch',
          height:'44px'
        })}>
            {menu?.map?.((mi, miIndex) => (
              <Tab
                {...mi.props}
                onClick={(e) => setActiveTab(mi?.name)}
                {...(mi.link ? { as: Link, href: mi.link } : {})}
                key={miIndex}
              >
                <HStack>
                  {mi?.icon?.(mi.name === activeTab ? { color: '' } : {color: ''})}
                  <Text wordBreak="">{mi.title}</Text>
                </HStack>
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Stack>}
    </>
  );
});
